
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        









































































































































































































































































































.mb-s {
  margin-bottom: 0.8em;
}
.vdp-datepicker__calendar {
  box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 10px;
  padding: 1em;
  .cell {
    color: black;
  }
  .cell.day {
    font-size: 1.4rem;
    &.selected {
      background: #5dae99;
      border-radius: 50%;
      color: white;
      &:hover {
        background: #5dae99;
      }
    }
    &.disabled {
      border-radius: 50%;
      background: #f8f7fa;
      color: #c1c1c1;
    }
  }
}
.weekend {
  background: none;
}
.vdp-datepicker {
  overflow: visible;
  display: inline-block;
  color: black;
}
.input-datepicker {
  color: yellow;
}
.color {
  color: $c-main-primary;
}
.selected-date {
  background: hsla(164, 33%, 52%, 0.13);
  border-radius: 20px;
  color: #5dae99;
  display: inline-block;
  margin-bottom: 15px;
  margin-top: 12px;
  padding: 6px 50px 6px 14px;
}
.record-title {
  background-image: url('../assets/images/ico-thumb.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 60px 60px;
  color: $c-main-primary;
  font-family: $ff-alt;
  margin-top: 15px;
  padding: 15px 0 15px 65px;
}
.align-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.content-date {
  padding-left: 2em;
  margin-bottom: 2em;
}
.info-text {
  font-size: 0.9em;
  line-height: 1em;
  margin-bottom: 1em;
}
button.arrow-right {
  background-image: url('../assets/svg/arrows/small/right-white.svg');
  background-position: right 15px center;
  background-repeat: no-repeat;
  padding-right: 40px;
}
.numColor {
  color: #ff4b00;
}
